import { home_gackground_url } from "../utils/constants";
import PlaceOrder from "./PlaceOrder";
const Home = () => {
    return <div className="flex flex-col md:flex-row bg-slate-800" >
        <div>
            <div className="h-[550px]">
                <img className="h-[450px] md:h-[550px] w-screen md:w-[850px]" alt = "background img" src = {home_gackground_url} />
            </div>
            <div className="-mt-[400px] md:-mt-80 pl-2 md:pl-8 text-xl md:text-2xl font-bold text-white">
                <p>NEED THE BEST ASSIGNMENT</p>
                <p>HELP IN UK ?</p>
                <div className="pt-8 text-lg md:text-xl font-semibold">
                    <p>LET OUR TRUSTED WRITING SERVICE </p>
                    <p>DO YOUR ESSAY FOR YOU !</p>
                </div>
                <div className="text-sm font-thin pt-8 flex">
                    <button className="bg-white px-4 py-2 rounded-lg text-black">
                        £ NO HIDDEN CHARGES
                    </button>
                    <button className="bg-yellow-300 text-green-800 px-4 py-2 rounded-lg mx-2">
                        £ MONEY BACK CURRENCY
                    </button>
                </div>
            </div>
        </div>
        <div className="h-[550px] text-white z-50 pt-24 pl-4">
            <PlaceOrder />
        </div>
    
    </div>
}

export default Home;