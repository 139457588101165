import { useNavigate } from "react-router-dom";
import { SampleData } from "../utils/constants";
const Samples = () => {
    const navigate = useNavigate();
    const gotoContactUs = () => {
        navigate("/contact")
    }
    return SampleData && <div className="pt-14 md:pt-28 px-3 bg-[#22CDD6] text-white">
        <div className="text-xl font-bold">SAMPLES</div>
        <div className="text-sm py-4">
            {SampleData.display}
        </div>
        <div>
            {
                SampleData.options.map((data)=>{
                    return ( <div className="py-4" key={data.value}>
                        <div className="flex flex-col md:flex-row">
                            <div className="w-full md:w-4/12">
                                <div className="font-bold">{data.heading}</div>
                                <div className="text-sm">{data.subHeading}</div>
                                <div className="mt-4 text-sm">{data.heading}</div>
                                <div className="mt-4 text-sm">{data.content}</div>
                            </div>
                            <div className="mt-4 text-sm pl-0 md:pl-20">
                                <div className="pb-2">Number Of pages <span className="font-bold">{data.numberOfPages}</span></div>
                                <div className="pb-2">Urgency <span className="font-bold">{data.Urgency}</span></div>
                                <div className="pb-2">Academic Level <span className="font-bold">{data.acadimicLevel}</span></div>
                                <div className="pb-2">Subject Area <span className="font-bold">{data.subArea}</span></div>
                                <div className="pb-2">Style <span className="font-bold">{data.style}</span></div>
                                <div className="pb-2">Number Of references <span className="font-bold">{data.numOfReferences}</span></div>
                            </div>
                        </div>
                    </div>)
                })
            }
        </div>
        <div className="py-3 text-black cursor-pointer" onClick={gotoContactUs}>Please visit Contact Us page for order !</div>
    </div>
}

export default Samples;