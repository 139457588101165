import { manvikData } from "../utils/constants";
import Select from "./Select";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { numOfPages } from "../utils/constants";
const PlaceOrder = () => {
    const [numberOfPages, setNumberOfPages] = useState(numOfPages.value);
    const navigate = useNavigate();

    const changeNumberOfPages = (e) => {
        if(e.target.value < 1) {
            setNumberOfPages(1);
            numOfPages.value = 1;
        }
        else{
            setNumberOfPages(e.target.value);
            numOfPages.value = e.target.value;
        }
    }
    
    const gotoOrderSummary = () => {
        navigate('/summary')
    }
    return <div>
        <p className="text-xl font-bold">PLACE AN ORDER</p>
            {
                manvikData.map(data => {
                    return <div className="pb-2" key={data.name}>
                                <Select selectObj={data}/>
                                <hr/>
                         </div>
                    
                })
            }
        <div className="pt-4 text-sm flex justify-between">
            <p>NUMBER OF PAGES</p>
            <input className="bg-slate-500 rounded-lg text-center" type="number" value={numberOfPages} onChange={changeNumberOfPages} />
        </div>
        <p className="pt-4 text-center">TOTAL PRICE: £ {numberOfPages * 2} </p>  
        <button className="bg-yellow-300 text-white px-16 py-2 rounded-lg mt-4" onClick={gotoOrderSummary}>ORDER NOW</button>  
    </div>
}

export default PlaceOrder;