import './App.css';
import Header from './Components/Header';
import './index.css';  
import Home from './Components/Home';
import { createBrowserRouter, Outlet } from 'react-router-dom';
import { RouterProvider } from 'react-router-dom';
import Services from './Components/Services';
import Error from './Components/Error';
import Menu from './Components/Menu';
import Samples from './Components/Samples';
import OrderSummary from './Components/OrderSummary';
import Contact from './Components/Contact';
import Writers from './Components/Writers';
import Login from './Components/Login';
import EmployeeDb from './Components/EmployeeDb';
import Career from './Components/Careers';

const Body = () => {
  return <div>
    <Header />
    <Outlet />
  </div>
}

function App() {
  const browserRouter = createBrowserRouter([
    {
      path: "/",
      element: <Body/>,
      errorElement: <Error/>,
      children: [
        {
          path: "/",
          element: <Home />
        },
        {
          path: "/services",
          element: <Services />
        },
        {
          path: "/menu",
          element: <Menu/>,
        },
        {
          path: "/samples",
          element: <Samples />
        },
        {
          path: "/summary",
          element: <OrderSummary />
        },
        {
          path: '/contact',
          element: <Contact />
        },
        {
          path: '/writers',
          element: <Writers />
        },
        {
          path: '/login',
          element: <Login />
        },
        {
          path:'/career',
          element: <Career />
        },
        {
          path: 'employees/:id',
          element: <EmployeeDb />
        }
      ]
    },
    
  ])
  return (
    <div className="">
        <RouterProvider router={browserRouter} />
    </div>
  );
}

export default App;
