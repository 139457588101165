import { Link } from "react-router-dom";
import { manvikData } from "../utils/constants";
import { numOfPages } from "../utils/constants";
const OrderSummary = () => {
    
    return <div className="pt-20 px-2 md:pt-32 w-12/12 md:w-1/2 m-auto">
        <div className="font-bold">YOUR ORDER SUMMARY</div>
        <div className="py-2">
            <hr/>
        </div>
        <div>
            {
                manvikData.map((data) => {
                    return <div key={data.value} className="py-2 text-sm">
                        <div className=""> {data.display} <span className="flex justify-end -mt-6 text-sm">{data.options[data.value].display}</span></div>
                    </div>
                })
            }
        </div>
        <div className="py-2 text-sm">Basic Price:<span className="flex justify-end -mt-6 text-sm">£{parseInt(numOfPages.value)*2}</span> </div>
        <div className="py-2 text-sm">Additional Charges:<span className="flex justify-end -mt-6 text-sm">£00</span> </div>
        <div className="py-2">
            <hr/>
        </div>
        <div className="pt-2 font-bold textlxl">Total Price:<span className="flex justify-end -mt-6 text-sm">£{parseInt(numOfPages.value)*2}</span></div>
        <Link to={"/contact"}><div className="py-8 text-blue-400 px-2 md:px-28">Contact us to confirm your order and get more details</div></Link>

    </div>
}
export default OrderSummary;