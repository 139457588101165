const Writers = () => {
    return <div className="h-12/12 pt-16 md:pt-24 px-4 md:px-20 w-12/12 bg-[#22CDD6] text-white">
        <div className="font-bold text-lg md:text-xl">OUR WRITERS</div>
        <div className="text-sm pt-4">A writing service that intends to do right by its customers has to be very careful about the writers they select.</div>
        <div className="font-bold text-lg md:text-xl pt-4">Here is what we do:</div>
        <div className="text-sm py-6">
            <div className="flex"> 
                <img src="https://tse2.mm.bing.net/th?id=OIP.-ID333kiEOesM3H-C_pH4QHaF_&pid=Api&P=0&h=180" className="h-6 w-6"/>
                <div className="pl-4">We insist that every writer submits to a tough screening process.</div>
            </div>
            <div className="pt-6 flex">
                <img src="https://tse2.mm.bing.net/th?id=OIP.-ID333kiEOesM3H-C_pH4QHaF_&pid=Api&P=0&h=180" className="h-6 w-6"/>
                <div className="pl-4">We verify writers’ submitted credentials – their degrees and their college transcripts</div>
            </div>
            <div className="pt-6 flex">
                 <img src="https://tse2.mm.bing.net/th?id=OIP.-ID333kiEOesM3H-C_pH4QHaF_&pid=Api&P=0&h=180" className="h-6 w-6"/>   
                <div className="pl-4">We make writers submit academic writing that they have produced and we evaluate that writing</div>
            </div>
            <div className="pt-4 flex">
                <img src="https://tse2.mm.bing.net/th?id=OIP.-ID333kiEOesM3H-C_pH4QHaF_&pid=Api&P=0&h=180" className="h-6 w-6"/>
                <div className="pl-4">We make writers take and pass a grammar/composition examination</div>
            </div>
            <div className="pt-4 flex">
                <img src="https://tse2.mm.bing.net/th?id=OIP.-ID333kiEOesM3H-C_pH4QHaF_&pid=Api&P=0&h=180" className="h-6 w-6"/>
                <div className="pl-4">We make writers produce an original piece of writing in their academic field on a topic we give them</div>
            </div>
            <div className="pt-4 flex">
                <img src="https://tse2.mm.bing.net/th?id=OIP.-ID333kiEOesM3H-C_pH4QHaF_&pid=Api&P=0&h=180" className="h-6 w-6"/>
                <div className="pl-4">We review the products that our writers craft before they are sent out to customers</div>
            </div>
        </div>

        <div className="font-bold text-lg md:text-xl">Here is how we assign writers to orders</div>
        <div className="text-sm py-6">
            <div className="flex"> 
                <img src="https://tse2.mm.bing.net/th?id=OIP.-ID333kiEOesM3H-C_pH4QHaF_&pid=Api&P=0&h=180" className="h-6 w-6"/>
                <div className="pl-4">All of our writers are placed in a database according to their specific degrees and levels of degrees.</div>
            </div>
            <div className="pt-6 flex">
                <img src="https://tse2.mm.bing.net/th?id=OIP.-ID333kiEOesM3H-C_pH4QHaF_&pid=Api&P=0&h=180" className="h-6 w-6"/>
                <div className="pl-4">When an order is submitted, it is analysed according to the details supplied by the customer</div>
            </div>
            <div className="pt-6 flex">
                 <img src="https://tse2.mm.bing.net/th?id=OIP.-ID333kiEOesM3H-C_pH4QHaF_&pid=Api&P=0&h=180" className="h-6 w-6"/>   
                <div className="pl-4">Only writers who have the correct academic credentials and background will be pulled from the database as possible writers for the project</div>
            </div>
            <div className="pt-4 flex">
                <img src="https://tse2.mm.bing.net/th?id=OIP.-ID333kiEOesM3H-C_pH4QHaF_&pid=Api&P=0&h=180" className="h-6 w-6"/>
                <div className="pl-4">A writer is assigned who is able to meet the customer’s deadline
                Read more at: https://ukwritings.com/writers</div>
            </div>
        </div>
        <div className="font-bold text-lg md:text-xl">Customer Order Details Determine Writer Assignment</div>
        <div className="text-sm pt-4">
        If a graduate student submits an order for a piece of writing, his/her writer will have a PhD in that research field. Likewise, when a high school student orders an assignment, the writer will have a minimum of a Bachelor’s degree. This is just one more way in which we guarantee that every client receives the perfect writer for his/her order.
        </div>

        <div className="font-bold text-lg md:text-xl pt-6">Your Choice</div>
        <div className="text-sm pt-4">So, how will you choose a writing service? Will you go with the cheapest price? Or will you select a writing service that employs qualified writers and pays them well to create original, perfectly written original pieces of writing? At UK Writings, we believe you want the latter and that when you get our products you will become the long-term customer we want a relationship with.</div>
    </div>
}

export default Writers;