import { Link, useNavigate } from "react-router-dom"
const Menu = () => {
    const navigate = useNavigate();

    const navigeToHome = () => {
        navigate("/")
    }
    return <div className="bg-slate-800 h-screen pt-14 text-white">
        <div className="flex justify-end px-4 text-xl" onClick={navigeToHome}>x</div>
        <div className="px-8">
            <Link to={"/samples"}><div className="py-4">Samples</div></Link>
            <hr/>
            <Link to={"/writers"}><div className="py-4">Our Writers</div></Link>
            <hr/>
            <Link to={"/contact"}><div className="py-4">Contact us</div></Link>
            <hr/>
            <Link to={"/career"}><div className="py-4">Careers</div></Link>
            <hr/>
            <Link to={"/login"}><div className="py-4">Login</div></Link>
            <hr/>
        </div>
    </div>
}

export default Menu