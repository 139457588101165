import { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../utils/firebase";
import { useNavigate } from "react-router-dom";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [statusMessage, setStatusMessage] = useState("");
    const navigate = useNavigate();

    const submitLogin = () => {
        console.log(email, password);
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            console.log(user, "user///");
            setStatusMessage("");
            navigate("/employees/123")
            // ...
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            setStatusMessage(error.message)
        });
    }

    return <div className="pt-2">
        <img alt="bg-img" src="https://tse2.mm.bing.net/th?id=OIP.egqxF4JaqX7a-oZTWqAv9gHaEo&pid=Api&P=0&h=180" className="h-screen w-screen absolute -z-10" />
        <form className="bg-black  text-white px-4 w-[300px] md:w-[400px] h-[250px] md:h-[300px] rounded-lg mx-auto my-24 md:my-32 opacity-65" onClick={(e) => e.preventDefault() }>
            <div className="font-semibold text-lg text-center pt-4">Login</div>
            <input type="email" className="mt-8 rounded w-full h-8 pl-2 text-black" placeholder="Email/Phone number" value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
            <input type="text" className="mt-8 rounded w-full h-8 pl-2 text-black" placeholder="Password" value={password} onChange={(e)=>{setPassword(e.target.value)}}/>
            <button className="bg-yellow-400 rounded py-1 w-full my-8" onClick={submitLogin}>Submit</button>
            <p className="text-red-500">{statusMessage}</p>
        </form>
    </div>
}

export default Login;
