import { useNavigate } from "react-router-dom";
import { useState } from "react";

const Header = () => {
    const [homePage, setHomePage] = useState(true);
    const navigate = useNavigate();
    const toggleHomePage = () => {
        setHomePage(!homePage);
        if(homePage) {
            // do nothing
        }
        else{
            navigate("/menu")
        }
    }

    const navigateToSelectedPage = (page) => {
        navigate(page)
    }

    return <div className="bg-black text-white flex justify-between text-lg fixed w-screen">
        <div className="flex px-2 py-1 md:pt-6">
            <p className="pr-24 py-2 md:pt-1 cursor-pointer text-yellow-300 font-bold font-serif" onClick={()=>navigateToSelectedPage('/')}>Manvik<span className="text-white">Solutions</span></p>
            <p className="pr-24 hidden md:block cursor-pointer" onClick={()=>navigateToSelectedPage('/samples')}>Samples</p>
            <p className="pr-24 hidden md:block cursor-pointer" onClick={()=>navigateToSelectedPage('/writers')}>Our writers</p>
            <p className="pr-24 hidden md:block cursor-pointer" onClick={()=>navigateToSelectedPage('/contact')}>Contact us</p>
            <p className="pr-24 hidden md:block cursor-pointer" onClick={()=>navigateToSelectedPage('/career')}>Careers</p>
            <p className="pr-24 hidden md:block cursor-pointer" onClick={()=>navigateToSelectedPage('/login')}>Login</p>
        </div>
        <div className="pr-2 pt-3 block md:hidden" onClick={toggleHomePage}>
            <div className="bg-white h-1 w-8 my-1 rounded"></div>
            <div className="bg-white h-1 w-8 my-1 rounded"></div>
            <div className="bg-white h-1 w-8 my-1 rounded"></div>
        </div>
    </div>
}

export default Header;