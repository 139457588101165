export const home_gackground_url = "https://img1.wsimg.com/isteam/stock/1148";

export const manvikData = [
    {
        name: "typeOfService",
        display: "TYPE OF SERVICE",
        value: "acadamicwriting",
        options: {
            acadamicwriting: {
                name: "acadamicwriting",
                display: "ACADEMIC PAPER WRITING",
                value: "0"
            },
            admsission: {
                name: "admsission",
                display: "ADMISSSION SERVICES",
                value: "1"
            },
            problems: {
                name: "problems",
                display: "MATHS/PHYSICS/ECONOMICS/STATICS PROBLEMS",
                value: "2"
            },
            mcq: {
                name: "mcq",
                display: "MULTIPLE CHOICE QUESTIONS",
                value: "3"
            },
            resume: {
                name: "resume",
                display: "RESUME/CV SERVICES",
                value: "4"
            },
            disseration: {
                name: "disseration",
                display: "DISSERATION/THESIS SERVICES",
                value: "5"
            },
            calculations: {
                name: "calculations",
                display: "WRITING INCL. CALCULATIONS",
                value: "6"
            },
            rewriting: {
                name: "rewriting",
                display: "REWRITING",
                value: "7"
            },
            copy: {
                name: "copy",
                display: "COPYWRITING",
                value: "8"
            }
        }
    },
    {
        name: "typeOfPaper",
        display: "TYPE OF PAPER",
        value: "essay",
        options: {
            essay: {
                name: "essay",
                display: "ESSAY",
                value: "0"
            },
            course: {
                name: "course",
                display: "COURSEWORK",
                value: "1"
            },
            research: {
                name: "research",
                display: "RESEARCH PAPER",
                value: "2"
            },
            term: {
                name: "term",
                display: "TERM PAPER",
                value: "3"
            },
            case: {
                name: "case",
                display: "CASE STUDY",
                value: "4"
            },
            assignment: {
                name: "assignment",
                display: "ASSIGNMENT",
                value: "5"
            },
            capstone: {
                name: "capstone",
                display: "CAPSTONE PROJECT",
                value: "6"
            },
            outline: {
                name: "outline",
                display: "OUTLINE",
                value: "7"
            },
            report: {
                name: "report",
                display: "REPORT",
                value: "8"
            },
            poster: {
                name: "poster",
                display: "POSTER",
                value: "9"
            },
            labReport: {
                name: "labReport",
                display: "LAB REPORT",
                value: "10"
            },
            article: {
                name: "article",
                display: "ARTICLE",
                value: "11"
            },
            other: {
                name: "other",
                display: "OTHER",
                value: "12"
            }
        }
    },
    {
        name: "academic",
        display: "ACADEMIC LEVEL",
        value: "ug",
        options: {
            ug: {
                name: "ug",
                display: "UNDER GRDUATE",
                value: "0"
            },
            grd: {
                name: "grd",
                display: "GRADUATE",
                value: "1"
            },
            mstr: {
                name: "mstr",
                display: "MASTER'S",
                value: "2"
            },
            dct: {
                name: "dct",
                display: "DOCTORAL",
                value: "3"
            },
            other: {
                name: "other",
                display: "OTHER",
                value: "4"
            }
        } 
    },
    {
        name: "urgency",
        display: "URGENCY",
        value: "3",
        options: {
            3: {
                name: "3",
                display: "3 HOURS",
                value: "0"
            },
            6: {
                name: "6",
                display: "6 HOURS",
                value: "1"
            },
            12: {
                name: "12",
                display: "12 HOURS",
                value: "2"
            },
            24: {
                name: "24",
                display: "24 HOURS",
                value: "3"
            },
            48: {
                name: "48",
                display: "48 HOURS",
                value: "4"
            },
            days3: {
                name: "days3",
                display: "3 DAYS",
                value: "5"
            },
            days7: {
                name: "days7",
                display: "7 DAYS",
                value: "6"
            },
            days14: {
                name: "dct",
                display: "14 DAYS",
                value: "7"
            },
            month: {
                name: "month",
                display: "1 MONTH",
                value: "8"
            }
        } 
    }

]

export const SampleData = {
    display: "Check out the samples of the writing assignments performed by Manvik Solutions experts",
    options: [
        {
            heading: "Role Of Nursing in Healthcare Delivery",
            subHeading: "#Essay",
            content: "One of the roles by a nurse is that of giving care. The criticality and adversity of a patient's condition will determine the level of care that the patients ...",
            numberOfPages: "2",
            Urgency: "5 days",
            acadimicLevel: "Undergraduate",
            subArea: "Nursing",
            style: "APA",
            numOfReferences: "3",
            amount: 100,
            value: 0
        },
        {
            heading: "Social Work in the Canadian Society",
            subHeading: "#Critical Essay",
            content: "Social work is a profession that deals with the assistance of persons, families, communities or groups in order to enhance their individual as well as ...",
            numberOfPages: "3",
            Urgency: "48 hours",
            acadimicLevel: "PhD",
            subArea: "Sociology",
            style: "Chicago",
            numOfReferences: "0",
            amount: 200,
            value: 1
        }
    ]
}

export const numOfPages = {
    value: 1
}
