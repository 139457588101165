const Contact = () => {
    return <div className="pt-[52px] md:pt-[65px] flex flex-col md:flex-row">
        <img src="https://img.freepik.com/free-vector/blue-contact-icons-background_23-2147607328.jpg?ga=GA1.1.1717874467.1731732102&semt=ais_hybrid" className="h-[300px] md:h-[500px] w-screen md:w-12/12" alt="bg-img"/>
        <div className="py-2 px-4 w-12/12 bg-blue-500 text-white">
            <div className="font-bold text-lg pt-8">Get in contact with us at anytime you need it! </div>
            <div className="py-4">We are ready to help you 24/7, even if it's a holiday or a middle of the night! Call us whenever you want and we will do our best to provide you with the best possible assistance</div>
            <div className="flex">
                <img alt="gmail" src="https://tse2.mm.bing.net/th?id=OIP.GPb0ZcxnKxbRAXHa3xNw3QHaHa&pid=Api&P=0&h=180" className="h-12 w-12" />
                <div className="text-black pt-3 pl-4">Support@manviksolutions.com</div>
            </div>
            <div className="flex pt-4">
                <img alt="contact" src="https://tse1.mm.bing.net/th?id=OIP.zFXtpPfmgn-_seJdrE8C3wHaHa&pid=Api&P=0&h=180" className="h-10 w-10"/>
                <div className="text-black py-2 pl-6">+447747213092</div>
            </div>
            <div className="py-8">No matter what questions you have - we are always ready to talk to you and help out. So do not hesitate to contact Support Department.</div>
        </div>
    </div>
}

export default Contact;