const Select = ({selectObj}) => {
    const changeServices = (e) => {
        selectObj.value = e.target.value;
    }

    return <div className="text-sm pt-4">
            <p className="text-slate-300">{selectObj.display}</p>
            <select className="text-white bg-slate-800 pt-2 w-11/12 md:w-[450px]" onChange={changeServices}>
                { 
                    Object.keys(selectObj.options).map(key=><option key = {selectObj.options[key].value} value = {selectObj.options[key].name}>{selectObj.options[key].display}</option>)
                }
            </select>
    </div>
}

export default Select;