
const Career = () => {
    return <div className="pt-[52px] md:pt-[65px] flex flex-col md:flex-row">
        <img src="https://img.freepik.com/premium-vector/career-website-banner-concept-with-thin-line-flat-design_56103-101.jpg?ga=GA1.1.1717874467.1731732102&semt=ais_hybrid" className="h-[250px] md:h-[500px] w-screen md:w-1/2" alt="bg-img"/>
        <div className="pt-4 md:pt-[10px] px-4 md:px-8 bg-[#1EC7EB] text-white">
            <div className="font-bold text-xl md:text-2xl">Your Career, Our Mission</div>
            <div className="py-4">At Manvik Solutions, we believe in empowering learners and creators. Join a dynamic team committed to delivering top-notch educational solutions.</div>
            <div className="font-bold text-xl md:text-2xl">what we offer</div>
            <div className="py-3">
                <div>1. Flexible work environment</div>
                <div>2. Opportunities for growth</div>
                <div>3. Collaborative culture</div>
            </div>
            <div className="font-bold text-xl md:text-2xl">Why work with us?</div>
            <div className="py-3">
                <div>1. Collaborative and supportive environment.</div>
                <div>2. Opportunities for growth and learning.</div>
            </div>
            <div className="font-bold text-xl md:text-2xl">Current Openings</div>
            <div className="py-3">Assignment/Essay writer</div>
            <div>Don’t see the role you’re looking for? Send us your CV at <span className="text-red-700"> Support@manviksolutions.com</span> —we’d love to hear from you!</div>
        </div>
    </div>
}

export default Career;